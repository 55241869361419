<template>
  <div class="register-page">
    <div v-if="!$system.user.isAuthenticated" class="container">
      <template v-if="loading">
        <div class="row register">
          <b-spinner variant="dark"></b-spinner>
        </div>
      </template>

      <template v-if="tokenStatus">
        <div class="row register">
          <b-form
            class="p-4 mt-5 mb-5 register__form"
            @submit.prevent="registrationUser"
          >
            <h3 class="mb-4 mt-2">Registration form</h3>
            <label class="mb-3">
              Email:
              <b-input type="email" disabled v-model="user.Email" />
            </label>
            <label class="mb-3">
              Password:*
              <b-input
                type="password"
                required
                v-model="user.Password"
                :state="validationPassword"
                :disabled="activeInput"
              />
            </label>
            <div v-if="errorPassword" class="password-error mb-3">
              {{ errorPassword }}
            </div>
            <label class="mb-3">
              Confirm password: *
              <b-input
                type="password"
                required
                v-model="user.PasswordRepeat"
                :state="validationRepeatPassword"
                :disabled="activeInput"
              />
            </label>
            <button class="btn btn-main" type="submit" :disabled="validForm">
              Submit
            </button>
          </b-form>
        </div>
      </template>
    </div>
    <div v-else class="container">
      <div class="row flex-center mt-5">
        <b-spinner variant="dark"></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import './RegisterPage.scss'
import RegisterService from '@/services/register/register.service'

export default {
  name: 'RegisterPage',
  metaInfo: {
    title: 'Registration',
    titleTemplate: '%s | ISO Portal | Nexi ',
  },
  data() {
    return {
      token: this.$route.query.token,
      tokenStatus: false,
      loading: false,
      activeInput: false,
      errorPassword: '',
      user: {
        Id: '',
        Email: '',
        ISOr: '',
        Password: '',
        PasswordRepeat: '',
      },
    }
  },
  mounted() {
    if (this.token && !this.$system.user.isAuthenticated) {
      this.loading = true
      this.checkToken(this.token)
    } else {
      this.$router.push('/')
    }
  },
  computed: {
    validForm() {
      if (
        this.user.Password == this.user.PasswordRepeat &&
        this.user.Password &&
        this.user.Password.length > 8 &&
        this.user.PasswordRepeat &&
        this.user.Password.search(/[a-z]/i) >= 0 &&
        this.user.Password.search(/[0-9]/) >= 0
      ) {
        return false
      } else return 'disabled'
    },

    validationPassword() {
      if (this.user.Password) {
        this.errorPassword = ''
        if (this.user.Password.length < 8) {
          this.errorPassword = '* Your password must be at least 8 characters'
          return false
        }
        if (this.user.Password.search(/[a-z]/i) < 0) {
          this.errorPassword =
            '* Your password must contain at least one letter.'
          return false
        }
        if (this.user.Password.search(/[0-9]/) < 0) {
          this.errorPassword =
            '* Your password must contain at least one digit.'
          return false
        }
        if (this.errorPassword.length > 0) {
          return false
        } else return true
      } else {
        this.errorPassword = ''
        return false
      }
    },

    validationRepeatPassword() {
      if (
        this.user.Password == this.user.PasswordRepeat &&
        this.user.PasswordRepeat
      ) {
        return true
      } else return null
    },
  },
  methods: {
    async checkToken(currentToken) {
      try {
        const response = await RegisterService.checkToken({
          token: currentToken,
        })
        if (
          response &&
          response.status == 'success' &&
          response.body &&
          !response.body.ISOr
        ) {
          if (this.$system.user.isAuthenticated) {
            this.$router.push('/')
          }
          this.user = response.body
          this.tokenStatus = true
          this.loading = false
        } else {
          this.$router.push('/')
          this.loading = false
        }
      } catch (err) {
        this.$system.makeToast(
          'danger',
          'Registration',
          'Token not found or already used!',
        )
        this.loading = false
        this.$router.push('/')
      }
    },

    async registrationUser() {
      try {
        if (this.user.Password == this.user.PasswordRepeat) {
          const response = await RegisterService.registrationUser(this.user)
          if (response && response.status == 'success') {
            this.activeInput = true
            this.$system.makeToast(
              'success',
              'Registration',
              'Account registered successfully!',
            )
            setTimeout(() => this.$router.push('/login'), 3000)
          }
        } else
          this.$system.makeToast(
            'primary',
            'Password ',
            'Passwords do not match!',
          )
      } catch (err) {
        this.$system.makeToast('danger', 'Registration', err)
      }
    },
  },
}
</script>
