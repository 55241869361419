import Axios from '../axios-instance/axios.service';

const API_URL =
  process.env.NODE_ENV !== 'development' ? `${window.location.origin}/api/` : 'http://localhost:5001/api/';

class RegisterService {

  async checkToken(token) {
    const { data } = await Axios.post(`${API_URL}token`,token);
    return data;
  }
  async registrationUser(user) {
    const { data } = await Axios.post(`${API_URL}registration`,user);
    return data;
  }

}

export default new RegisterService();